.btn {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;

  font-size: 1.125rem;

  &:disabled {
    cursor: default;
  }
}

.btn-icon {
  background: none;
  padding: 0;
  height: auto;
}
.btn-white-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(31, 79, 128, 0.3);
  background: #ffffff;
  min-width: 3rem;
  height: 3rem;
  padding: 0.75rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: #e9edf2;
  }
  &:active {
    background: #d2dce6;
  }
}
.btn-white-text {
  background: none;
  font-weight: 500;
  color: $c-primary;
  @extend .btn;
}
.btn-primary {
  @extend .btn;
  color: #fff;
  background: $c-brown;

  &:hover {
    background: #99734c;
  }
  &:active {
    background: #804f1f;
    box-shadow: inset 0px 0px 5px rgba(68, 39, 10, 0.73);
  }
  &:disabled {
    background: #091630;
    opacity: 0.3;
  }
}

.btn-secondary {
  @extend .btn;
  color: #fff;
  background: $c-primary;

  &:hover {
    background: #4c7399;
  }
  &:active {
    box-shadow: inset 0px 0px 5px rgba(31, 79, 128, 0.73);
  }

  .icon-wrapper svg path {
    fill: #fff;
  }
}

.btn-white {
  @extend .btn;
  background: rgba(9, 22, 48, 0.05);
  color: rgba(9, 22, 48, 0.3);
}

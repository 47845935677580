.table-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;

  table.table {
    display: grid;
    grid-auto-rows: min-content;
    flex: 1;
    width: 100%;
    margin-bottom: 1rem;

    thead,
    tbody,
    tr {
      display: contents;
    }

    tr.sub-row {
      td {
        padding: 0;
        border-bottom: none;
        overflow: hidden;
      }
    }

    th,
    td {
      display: flex;
      align-items: center;

      &.cell-align-left {
        justify-content: flex-start;
        text-align: left;
      }

      &.cell-align-center {
        justify-content: center;
        text-align: center;
      }

      &.cell-align-right {
        justify-content: flex-end;
        text-align: right;
      }
    }

    th {
      align-items: center;
      background: rgba(143, 167, 192, 0.3);
      padding: 0.5rem 1.5rem;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 140%;
      color: #091630;
      text-transform: uppercase;

      @include responsive(desktop) {
        font-size: 1rem;
      }

      > * + * {
        margin-left: 0.5rem;
      }

      .filter,
      .react-datepicker-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    td {
      padding: 1.125rem 1.5rem;
      border-bottom: 1px solid rgba(143, 167, 192, 0.3);
      font-size: 0.75rem;
      line-height: 140%;
      color: #091630;

      @include responsive(desktop) {
        font-size: 1rem;
      }
    }

    .table__no-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      color: rgba(31, 79, 128, 0.3);
      padding: 5rem;
    }
  }
}

.tool-positioner {
  position: absolute;
  z-index: 1000;
  opacity: 1;
  transition: all 0.3s;

  &.hidden {
    opacity: 0;
  }

  &.h-left {
    left: unset;
    right: calc(100% + 8px);
  }

  &.h-right {
    left: calc(100% + 8px);
    right: unset;
  }

  &.h-center {
    left: 50%;
    right: unset;
    transform: translateX(-50%);

    &.v-top {
      top: unset;
      bottom: calc(100% + 8px);
    }

    &.v-bottom {
      top: calc(100% + 8px);
      bottom: unset;
    }
  }

  &.v-top {
    top: unset;
    bottom: 0;
  }

  &.v-center {
    top: unset;
    bottom: unset;
  }

  &.v-bottom {
    top: 0;
    bottom: unset;
  }

  .toolbar {
    position: static;
    transform: none;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  &__arrow {
    cursor: pointer;
    transition: all 0.3s;
    background: none;
    border: none;

    &:disabled {
      opacity: 0.5;
      cursor: pointer;
    }

    &:hover:not(:disabled) {
      transform: scale(1.05);
    }

    &.left {
      margin-right: 34px;
    }
    &.right {
      margin-left: 34px;
    }
  }
  &-bullets {
    display: flex;
    align-items: center;
  }
  &__bullet {
    color: #4c7399;
    cursor: pointer;
    margin-right: 24px;
    transition: all 0.3s;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      font-weight: 500;
      font-size: 20px;
    }

    &:hover {
      color: $c-primary;
    }
  }
}

.case-preview-page {
  @extend .dfh;

  .cases-comment {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 74rem;
    height: 14.25rem;
    background: $c-primary;
    line-height: 140%;
    color: #ffffff;
    padding: 1.5rem 3.5rem;

    &__title {
      font-weight: 500;
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
    }
    &__text {
      p {
        margin-bottom: 0.75rem;
      }
      font-size: 1rem;
    }
    &__close {
      top: 1.5rem;
      right: 2.25rem;
      position: absolute;
      svg path {
        fill: #fff;
      }
    }
    &-bottom {
      display: flex;
      align-items: center;

      .btn-primary {
        margin-left: auto;
      }
    }
  }

  .case-preview {
    @extend .dfh;
    flex-direction: row;
    max-height: 100vh;
    &-left {
      min-width: 9rem;

      a {
        margin: 2.5rem 0 0 1.25rem;
        display: flex;
        align-items: center;
        color: $c-primary;
        text-decoration: none;
        svg {
          margin-right: 0.5rem;
        }
      }
    }
    &-ilustration {
      min-width: 34.5%;
      max-width: 34.5%;
      display: flex;
      img {
        width: 100%;
        // max-height: calc(100vh - 3.5rem);
        object-fit: cover;
      }
    }
    &-content {
      padding: 2.5rem 9rem 2.5rem 2rem;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      line-height: 140%;
      max-height: 100vh;
      overflow-y: scroll;

      &__type {
        padding: 0 0.5rem;
        border: 1px solid $c-primary;
        border-radius: 0.25rem;
        text-align: center;
        text-transform: uppercase;
        font-size: 0.75rem;
        line-height: 140%;
        color: $c-primary;
        opacity: 0.5;
      }

      &__subtitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 140%;
        color: #091630;
      }

      &__title {
        font-weight: 600;
        line-height: 140%;
        font-size: 3.5rem;
        color: $c-primary;
        margin-bottom: 2.25rem;
      }

      &__text {
        color: #373737;
        font-size: 1rem;
        margin-bottom: 4.5rem;
        p {
          margin-bottom: 0.75rem;
        }
      }

      ul {
        margin-top: 0;
      }

      .dropdown-list {
        border-bottom: 1px solid rgba(31, 79, 128, 0.3);
        &-top {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 1.125rem;
          line-height: 140%;
          color: $c-primary;
          padding: 1rem 0;
        }
        &-content {
          margin-bottom: 1rem;
          overflow: hidden;
        }
        &__activator {
          cursor: pointer;
          margin-left: auto;
        }
      }
    }

    &-help-widget {
      padding: 1.5rem;
      margin: 1rem 0;
      background: #ffffff;
      border: 1px solid rgba(31, 79, 128, 0.3);
      box-shadow: 0 2px 12px rgba(31, 79, 128, 0.24);

      &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        &__title {
          font-weight: 500;
          font-size: 1rem;
          line-height: 140%;
          text-transform: uppercase;
          color: $c-primary;
        }

        &__close {
          cursor: pointer;
        }
      }

      &-content {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 0.625rem;
        align-items: center;

        .link-activator {
          width: 1.875rem;
          height: 1.875rem;
          background: rgba(65, 212, 65, 0.3);
          border: 2px solid #008000;
        }

        .comment-activator {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1.875rem;
          height: 1.875rem;
          border-radius: 50%;
          border: 1px solid rgba(31, 79, 128, 0.3);
          box-shadow: 0 1px 4px rgba(31, 79, 128, 0.15);
        }
      }
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      margin-top: auto;

      &-controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > * + * {
          margin-left: 1rem;
        }
      }

      .btn-icon {
        width: 3rem;
        height: 3rem;
        border: 1px solid rgba(31, 79, 128, 0.3);
        background: #ffffff;
        transition: all 0.3s;

        &:hover {
          background: #e9edf2;
        }

        &:active,
        &--active {
          background: #d2dce6;
        }
      }
    }

    &-rate__btn {
      border: 1px solid rgba(31, 79, 128, 0.3);
      width: 4.625rem;
      height: 2.9375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @extend .t-md;
      cursor: pointer;
      color: $c-primary;
      svg {
        margin-left: 0.25rem;
      }
    }
  }
}

.crop > .modal-wrapper > .modal {
  width: 50%;
  max-height: 90vh;
}

.case-preview-step {
  position: relative;

  &.image {
    & > img {
      width: 100%;
    }
  }

  &.video {
    height: 100%;
    max-height: calc(100vh - 3.5rem);
    overflow: hidden;

    & > video {
      width: 100%;
      height: 100%;
      max-height: calc(100vh - 3.5rem);
    }
  }

  .lazy-load-image-loaded {
    width: 100%;
  }

  .hotspot {
    position: absolute;
    background: rgba(31, 79, 128, 0.3);
    border: 1px solid #1f4f80;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .comment {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    .comment-activator {
      position: absolute;
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      background: white;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      // border: 1px solid rgba(31, 79, 128, 0.3);
      // box-shadow: 0 1px 4px;
      border: 1px solid rgba(31, 79, 128, 0.3);
      box-shadow: 0 1px 4px rgba(31, 79, 128, 0.15);
      cursor: pointer;
    }
    .link-activator {
      width: 100%;
      height: 100%;
      background: rgba(65, 212, 65, 0.3);
      border: 2px solid #008000;
      // background: rgba(31, 79, 128, 0.3);
      // border: 1px solid rgba(31, 79, 128, 0.3);
      // box-shadow: 0 1px 4px;
      cursor: pointer;
      border-radius: 0.5rem;
    }

    .comment-card {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
      color: $c-primary;
      border-radius: 0.5rem;
      padding: 1.5rem 2rem;
      width: 500px;
      background: #ffffff;
      border: 1px solid rgba(31, 79, 128, 0.3);
      overflow: auto;

      form {
        max-width: 100%;
      }

      &.viewer {
        position: static;
        transform: none;
      }

      &__top {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        .btn-icon {
          margin-left: 1rem;
        }
      }

      &__title {
        font-weight: 500;
        font-size: 1.25rem;
        margin-right: auto;
      }
      &__content {
        margin-bottom: 1rem;
        color: #091630;
      }
      &__text {
        p {
          margin-bottom: 0.75rem;
        }
        font-size: 1rem;
      }
      &__close {
        top: 1.5rem;
        right: 2.25rem;
        position: absolute;
        svg path {
          fill: #fff;
        }
      }
      &-bottom {
        display: flex;
        align-items: center;

        .btn-primary {
          margin-left: auto;
        }
      }

      &-label {
        > * + * {
          margin-top: 1.5rem;
        }

        &__description {
          font-weight: 500;
          font-size: 20px;
          line-height: 140%;
          color: #1F4F80;
        }

        &__name {
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: #091630;
        }
      }

      &-submit-wrapper {
        display: flex;
        justify-content: space-between;
        flex: 1;

        .btn-icon {
          margin-left: 1rem;
        }

        &-top-right {
          display: flex;
          position: absolute;
          top: -0.5rem;
          right: 0;
        }
      }
    }

    .comments-pagination {
      display: flex;
      align-items: center;

      &__arrow {
        cursor: pointer;
        transition: all 0.3s;
        background: none;
        border: none;

        &:disabled {
          opacity: 0.5;
          cursor: pointer;
        }

        &:hover:not(:disabled) {
          transform: scale(1.05);
        }

        &.left {
          margin-right: 1.625rem;
        }

        &.right {
          margin-left: 1.625rem;
        }
      }

      &-pages {
        display: flex;
        align-items: center;

        > * + * {
          margin-left: 1rem;
        }
      }

      &__page {
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        color: #8fa7c0;
        transition: all 0.3s;

        &._active {
          font-size: 18px;
          color: $c-primary;
        }
      }
    }

    // &.left .comment-card {
    //   right: calc(100% + 2rem);
    // }
    // &.right .comment-card {
    //   left: calc(100% + 2rem);
    // }
    // &.top .comment-card {
    //   // right: calc(100% + 2rem);
    //   top: 0;
    //   transform: translateY(25%);
    // }
    // &.bottom .comment-card {
    //   // left: calc(100% + 2rem);
    //   bottom: 0;
    //   transform: translateY(-25%);
    // }
    .comment-active-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(31, 79, 128, 0.3);
      border: 1px solid #1f4f80;
    }
    &.active {
      .comment-activator {
        display: none;
      }
      .comment-active-area {
        box-shadow: 0 0 0 6000rem rgba(0, 0, 0, 0.5);
        background: none;
        border: none;
        border-radius: 0.5rem;
      }
    }
  }

  .comments-order-list {
    display: flex;
    flex-direction: column;
    max-height: 13.5rem;
    overflow: auto;
    border: 1px solid rgba(31, 79, 128, 0.3);
    padding: 0;
    margin: 0;
    counter-reset: number;
    list-style-type: none;

    &-item {
      background: #ffffff;
      padding: 9px 17px 0 38px;
      position: relative;
      cursor: grab;
      transition: background 0.3s;

      &:not(:last-child) {
        .comments-order-list-item__underline {
          //position: absolute;
          //bottom: -1px;
          //left: 0;
          width: 100%;
          height: 1px;
          background: #E9EDF2;
        }
      }

      &::before {
        counter-increment: number;
        content: counter(number)"\a0";
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #1F4F80;
        position: absolute;
        left: 16px;
      }

      &_dragging {
        background: #E9EDF2;
        cursor: grabbing;
      }

      &__title {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #091630;
      }

      &__description {
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: rgba(0, 0, 0, 0.3);
        margin-bottom: 4px;
      }
    }
  }

  .step-description {
    position: absolute;
    top: 7rem;
    right: 0.5rem;
    background: #ffffff;
    border: 1px solid rgba(31, 79, 128, 0.3);
    border-radius: 0.75rem;
    padding: 1.5rem 2rem;
    max-width: 31.25rem;
    min-width: 300px;
    z-index: 100;

    &__name {
      color: $c-primary;
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 140%;
    }
    &__close {
      position: absolute;
      top: 1.5rem;
      right: 2rem;
      padding: 0;
      background: none;
      border: none;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.link-card {
  width: 36rem;
  .t-md {
    color: #091630;
  }

  .link-select-wrapper {
    display: flex;
    border: 1px solid rgba(31, 79, 128, 0.3);
    margin: 0.25rem 0 2rem;
    max-height: min(90vh, 400px);
    overflow-y: auto;

    .link-select {
      &__item {
        height: 2.5rem;
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        cursor: pointer;
        background: #fff;
        border-bottom: 1px solid #e9edf2;
        @extend .t-md;
        color: $c-primary;

        &.add {
          @extend .t-sm;
        }

        &:not(.add) {
          span {
            max-width: calc(100% - 1.5rem);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .icon-wrapper {
            transform: rotate(-90deg);
          }
        }

        &:hover,
        &.active {
          background: #e9edf2;
        }

        &.disabled {
          opacity: 0.5;
          cursor: default;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      &:nth-child(1) {
        border-right: 1px solid #e9edf2;
        margin-right: 1rem;
        width: 50%;
      }

      &:nth-child(2) {
        width: calc(50% - 1rem);
      }
    }
  }
}

.loader-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.inline {
    position: relative;
  }

  .dots {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    &__item {
      background: #8fa7c0;
      height: 0.625rem;
      width: 0.625rem;
      border-radius: 50%;
      margin-right: 0.625rem;
      transition: all 0.3s;

      &.active {
        background: $c-primary;
        transform: translateY(-50%);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .loader-label {
    color: $c-primary;
    max-width: 90vw;
  }
}

.create-comment {
  cursor: pointer;
  .comment-active-area {
    position: absolute;
    background: rgba(31, 79, 128, 0.3);
    border: 1px solid #1f4f80;
  }
}

.content {
  display: flex;
  flex: 1;
}
.constructor {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;

  .constructor-top {
    display: flex;
    align-items: center;
    height: 2.75rem;
    position: relative;
    filter: drop-shadow(0px 1px 4px rgba(31, 79, 128, 0.15));
    background: #ffffff;

    .constructor-top__logo {
      width: 14.75rem;
      min-width: 14.75rem;
      max-width: 14.75rem;
      padding-left: 1rem;
      font-weight: bold;
      color: $c-primary;
    }

    .constructor-top-breadcrumbs {
      color: $c-primary;
      button {
        padding: 0;

        &:disabled {
          color: $c-primary;
          opacity: 1;
          cursor: default;
        }
      }
      span {
        margin: 0 0.5rem;
      }
    }

    button,
    a {
      padding: 0.5rem 1rem;
      background: none;
      border: none;
      @extend .t-md;
      // .down-line-hover-effect;
      transition: all 0.3s;
      color: $c-primary;
      cursor: pointer;
      svg path {
        transition: all 0.3s;
      }
      // &::after {
      //   bottom: 0.5rem;
      //   left: 1rem;
      // }
      // &:hover {
      //   svg path {
      //     fill: $c-primary;
      //   }
      //   &::after {
      //     width: calc(100% - 2rem) !important;
      //   }
      // }
      &:disabled {
        color: #091630;
        opacity: 0.3;
      }
    }

    .delim {
      margin-left: auto;
    }
  }

  .constructor-content {
    display: flex;
    flex-grow: 1;

    &-left {
      width: 14.75rem;
      min-width: 14.75rem;
      max-width: 14.75rem;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      box-shadow: 1px 1px 4px rgba(31, 79, 128, 0.15);
      max-height: calc(100vh - 6.25rem);
      .overflow-area {
        overflow-y: auto;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
      }

      .nav-item {
        width: 100%;
        background: none;
        border: none;
        color: $c-primary;
        font-size: 0.875rem;
        line-height: 140%;

        cursor: pointer;
        text-align: left;
        display: flex;
        align-items: center;
        transition: all 0.3s;

        .icon-wrapper {
          min-width: 1.5rem;
        }

        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &:hover {
          background: #e9edf2;
        }

        &.stage {
          border-top: 1px solid #e9edf2;
          padding: 0.5rem 1rem !important;

          &.add {
            margin-top: auto;

            @extend .btn-secondary;
          }
        }

        &.step {
          padding: 0.5rem 1.5rem;
          border-bottom: none;

          &.add {
            width: 2rem;
            height: 2rem;
            color: rgba(31, 79, 128, 0.3);
            align-self: center;
            padding: 0;

            svg {
              margin: 0;
            }

            svg path {
              transition: all 0.3s;
              fill: rgba(31, 79, 128, 0.3);
            }

            &:hover {
              color: $c-primary;

              svg path {
                fill: $c-primary;
              }
            }
          }
        }

        &.active {
          background: #e9edf2;
        }

        &.add {
          border: 1px solid rgba(31, 79, 128, 0.3);
          border-radius: 0.25rem;
          height: 2.5rem;
          margin: 1rem;
          width: auto;
          justify-content: center;
          color: $c-primary;
          padding: 0 1rem;
          font-weight: 600;
          font-size: 0.875rem !important;
          svg {
            margin-right: 0.5rem;
            width: 1.5rem;
          }
        }
      }
    }
    &-right {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 6.25rem);
      overflow: auto;

      .ReactCrop {
        width: 100%;
      }

      .c-form {
        padding: 1rem 30% 1rem 1rem;

        .t-title {
          margin-bottom: 1.5rem;
        }

        .btn-secondary {
          margin-left: auto;
        }
      }

      .c-view {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 0 1.5rem;

        &-top {
          display: flex;
          align-items: center;
          padding: 1.5rem 0;
          &.border-bottom {
            padding: 1rem 1rem;
            border-bottom: 1px solid #e9edf2;
          }
          .editable-field {
            width: 22.5rem;
          }

          &__logo {
            width: 2.75rem;
            height: 2.75rem;
            margin-right: 1rem;
          }

          &__type {
            padding: 0 0.5rem;
            margin-left: 5rem;
            border: 1px solid $c-primary;
            border-radius: 0.25rem;
            text-align: center;
            text-transform: uppercase;
            font-size: 0.75rem;
            line-height: 140%;
            color: $c-primary;
            opacity: 0.5;
          }

          .t-title {
            font-size: 1.5rem;
          }

          &__left {
            margin-left: auto;
          }

          button {
            padding: 0 1rem;
            background: none;
            border: none;
            cursor: pointer;

            svg {
              width: 1.5rem;
              height: 1.5rem;
            }

            &:disabled {
              opacity: 0.5;
              cursor: default;
            }
          }
        }
      }
    }
  }

  .link-form {
    justify-content: space-between;

    .form__field {
      flex: 0 1 45%;
    }
  }
}
.case-description__block {
  .t-lg {
    font-weight: 500;
    color: #091630;
    margin-bottom: 1rem;
  }
  .t-md {
    color: #373737;
  }
  padding: 1rem;
  border-bottom: 1px solid #e9edf2;

  &:last-child {
    border-bottom: none;
  }
}
.case-description-content {
  display: flex;
  width: 100%;

  &__left {
    padding-top: 1rem;
    margin-right: 1rem;

    img {
      border: 1px solid $c-primary;
      width: 15rem;
      height: 26.875rem;
      object-fit: cover;
    }
  }
  &__right {
    margin-right: 3.5rem;
    overflow-wrap: break-word;
    width: calc(100% - 16rem);
    // .case-description__block {
    //   padding-left: 1.5rem;
    // }
  }
}
.new-step__btn {
  height: 9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //border: 1px dashed $c-primary;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%231f4f80' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  color: $c-primary;
  margin: 0 2rem 3rem 6.25rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%231f4f80' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") rgba(0, 0, 0, 0.05);
  }
}

.steps-container {
  display: flex;

  .order-list {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    margin: 0 2.625rem 0 1.125rem;

    .step-order {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 9rem;
      margin-bottom: 0.625rem;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        border: 1px solid #1F4F80;
        filter: drop-shadow(0px 1px 4px rgba(31, 79, 128, 0.15));
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 140%;
        color: $c-primary;
        text-align: center;
      }
    }

    &:last-child .step-order {
      border-left: none;
    }
  }

  .steps-list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    flex: 1;
    margin: 0;
    padding: 0;

    .step-item {
      display: flex;
      flex-grow: 1;
      margin-bottom: 0.625rem;
    }

    .step-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1.5rem;
      margin-left: 0.625rem;

      .btn-icon {
        margin: 1.5rem 0;
      }
    }

    .step-card {
      height: 9rem;
      display: flex;
      flex-grow: 1;
      background: #ffffff;
      cursor: grab;
      transition: all 0.3s;

      &_dragging {
        background: #d2dce6;
        cursor: grabbing;
      }

      &__image {
        width: 19rem;
        height: 9rem;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(31, 79, 128, 0.3);
        // box-sizing: content-box;
        pointer-events: none;
      }
      img.step-card__image {
        // border: none;
      }
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid rgba(31, 79, 128, 0.3);
        border-left: none;
        padding: 0.75rem 1.5rem 1rem 1rem;
        flex-grow: 1;

        &-top {
          margin: 0 -0.5rem;
          padding-bottom: 0.25rem;

          .editable-field-wrapper {
            width: 100%;

            .editable-field {
              width: 100%;

              input {
                font-weight: 400;
                font-size: 1rem;
                line-height: 140%;
                color: #091630;

                &[disabled] {
                  pointer-events: none;
                }

                &:read-only {
                  border-color: transparent;
                }
              }
            }
          }
        }

        &-bottom {
          display: grid;
          grid-template-columns: auto auto 1fr;
          justify-items: flex-end;
          align-items: center;
          gap: 2.5rem;
        }

        &__btn {
          display: flex;
          align-items: center;
          border-radius: 0.25rem;
          padding: 0.2rem;
          transition: all 0.3s;

          &.no-content {
            opacity: 0.3;
          }

          &:not(.no-content) {
            cursor: pointer;

            &:hover {
              background: rgba(156, 157, 159, 0.2);
            }
          }

          b {
            font-weight: 600;
            font-size: 1rem;
            line-height: 140%;
            color: $c-primary;
          }
        }
      }
    }
  }

  .info-card {
    width: min(28rem, 90vw);
    background: #ffffff;
    padding: 1.5rem 2rem;
    border: 1px solid #E9EDF2;
    box-shadow: 0 2px 12px rgba(31, 79, 128, 0.24);
    cursor: default;

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;

      &__title {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 140%;
        color: $c-primary;
      }

      &__close {
        cursor: pointer;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      max-height: min(8.75rem, 90vh);
      overflow-y: auto;
      overflow-x: hidden;

      &__item {
        padding-bottom: 0.5rem;
        border-bottom: 1px solid rgba(31, 79, 128, 0.3);
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 140%;
        color: $c-primary;

        &.link {
          text-decoration: none;
          cursor: pointer;
        }
      }

      > * + * {
        margin-top: 1rem;
      }

      &-comment {
        &-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &__details {
          margin-left: 1rem;
          overflow: hidden;
          font-size: 0.75rem;
          color: #000000;
        }
      }
    }
  }
}

.constructor-form-separator {
  padding: 1.5rem 1rem 0;
  margin: 0 -1rem;
  border-top: 1px solid #e9edf2;
}

.case-feedback {
  width: 37rem;

  &__title {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 140%;
    color: $c-primary;
    margin-bottom: 2rem;
  }
  label {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 140%;
    color: $c-primary;
    margin-bottom: 1rem;
    display: block;
  }
  .rate-input {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    .rate-square {
      border: 1px solid rgba(9, 22, 48, 0.15);
      border-radius: 0.625rem;
      width: 3.75rem;
      height: 3.75rem;
      margin-right: 0.5rem;
      color: rgba(9, 22, 48, 0.3);
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s;

      &:hover,
      &.active {
        color: #fff;
        background: $c-primary;
        border-color: $c-primary;
      }
    }
  }
  &__field {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    label {
      margin: 2.5rem 0 1rem;
      color: #091630;
    }

    textarea {
      resize: none;
      padding: 0.75rem 1rem;
    }
  }

  .rate-input {
    display: flex;
  }
  .form__message {
    top: calc(100% + 0.25rem);
    left: 1rem;
  }
  textarea {
    resize: none !important;
  }

  button {
    margin-top: 2.5rem;
  }
}

.crop-area {
  position: absolute;
}

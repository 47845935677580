.form {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.form__field {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  margin-bottom: 2.75rem;
  box-sizing: border-box;
  max-width: 100%;
}
.form__input-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  & > * {
    width: 100%;
  }

  @include responsive(desktop) {
    flex-direction: row;
  }
}
.form__input-icon-wrapper {
  display: flex;
  align-items: center;
  position: relative;

  .show-password-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.3s;

    &:hover {
      opacity: 1;
    }
  }
}
.form__input {
  border: 1px solid rgba(31, 79, 128, 0.3);
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  color: #091630;
  outline: none;
  transition: all 0.3s;
  cursor: pointer;
  background: transparent;
  font-size: 1rem;
  width: 100%;

  &::placeholder {
    color: rgba(9, 22, 48, 0.3);
  }
  &:focus {
    border: 1px solid $c-primary;
  }
  &:disabled {
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }

  &.error {
    border-color: $c-red;
  }

  &.password {
    padding-right: 3.5rem;
  }
}
.form__maxlength {
  @extend .t-sm;
  color: $c-primary;
  margin: 0.25rem 0 0 auto;
  position: absolute;
  top: calc(100%);
  right: 0;
  text-align: right;

  &.error {
    color: $c-red;
  }
}
.form__richtext-input {
  display: flex;
  flex-direction: column;

  .ql-container.ql-snow {
    border: 1px solid rgba(31, 79, 128, 0.3);
    transition: all 0.3s;
  }

  &.error .ql-container.ql-snow {
    border-color: $c-red;
  }
}
.ql-editor {
  padding: 0.75rem 1rem !important;
  min-height: 7.5rem;
  font-family: $font-primary;
  font-size: 1rem;
}
.ql-editor.ql-blank::before {
  font-style: normal;
  color: rgba(9, 22, 48, 0.3);
}
.quill {
  color: #091630;
}

textarea.form__input {
  resize: vertical;
  min-height: 7.5rem;
  font-family: 'Montserrat', sans-serif;
}
.form__message {
  position: absolute;
  top: 100%;
  margin-top: 0.125rem;
  color: $c-primary;
  opacity: 0.5;
  transition: all 0.3s;
  @extend .t-md;

  &--error {
    color: $c-red;
    opacity: 1;
  }
}
.form__global-error {
  flex: 1 0 100%;
}
.form__label {
  font-weight: 500;
  margin-bottom: 8px;
}
.form__label .label-optional {
  display: inline-block;
  color: $c-primary;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;
  transform: translateY(-50%);
  margin-left: 0.25rem;
}
.form__checkboxWrapper {
  display: flex;
}
.form__checkbox {
  cursor: pointer;
}
.form__radio-wrapper input:checked {
  accent-color: $c-primary;
  opacity: 1;
}
.form__radio-wrapper input:checked + label {
  opacity: 1;
}
.form__radio-wrapper {
  display: flex;
  align-items: center;
  width: auto;
  transition: all 0.3s;

  &:not(:first-child) {
    margin-left: 1.875rem;
  }

  &.outlined {
    padding: 0.625rem 1rem;
    border: 1px solid rgba(31, 79, 128, 0.3);
  }

  label {
    display: flex;
    align-items: center;
    transition: all 0.25s ease-out;
    cursor: pointer;
  }

  input[type='radio'] {
    position: absolute;
    display: none;

    &:disabled,
    &:not(:checked) {
      + label {
        opacity: 0.3;
      }
    }

    + label {
      &:before {
        content: '';
        border-radius: 100%;
        border: 1px solid $c-primary;
        display: inline-block;
        min-width: 1rem;
        min-height: 1rem;
        position: relative;
        margin-right: 1em;
        cursor: pointer;
        transition: all 0.25s ease;
      }
    }

    &:checked {
      + label {
        &:before {
          box-shadow: inset 0 0 0 2px #ffffff;
          background: $c-primary;
        }
      }
    }

    &:focus {
      + label {
        &:before {
          outline: none;
          border-color: $c-primary;
        }
      }
    }

    + label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.form.submitted > * {
  opacity: 0;
}
.form.submitted .form__submitedSlot {
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

.search {
  position: relative;
  margin-left: auto;
  // margin-right: auto;
  width: 1.5rem;
  transition: all 0.3s;

  &.active {
    width: 100%;
    input {
      width: 100%;
      padding: 0.5rem 1rem 0.5rem 2.5rem;
    }
    .icon-wrapper {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    @include responsive(desktop) {
      width: 24rem;
    }
  }
  .icon-wrapper {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    // width: 100%;
    width: 0;
    border: none;
    outline: none;
    padding: 0;
    border-bottom: 1px solid rgba(31, 79, 128, 0.3);
    color: $c-primary;
    font-size: 1rem;
    &::placeholder {
      color: rgba(31, 79, 128, 0.3);
    }
  }
}

.switch-wrapper {
  display: flex;
  align-items: center;

  .switch__label {
    color: $c-primary;
    @extend .t-md;
    margin-right: 0.5rem;
  }
  .switch {
    background: #d2dce6;
    border-radius: 0.75rem;
    padding: 1px;
    width: 2.5rem;
    height: 1.125rem;
    cursor: pointer;
    transition: all 0.3s;

    &__value {
      border-radius: 50%;
      height: 1rem;
      width: 1rem;
      background: #ffffff;
      position: relative;
      left: 0;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 3px 1px rgba(0, 0, 0, 0.06);
      transition: all 0.3s;
    }
  }
  &.active {
    .switch {
      background: $c-primary;

      &__value {
        left: 100%;
        transform: translateX(-100%);
      }
    }
  }
}

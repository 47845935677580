.analytic-page {
  display: flex;
  flex-direction: column;
  flex: 1;

  .analytic-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 1rem 0;

    @include responsive(desktop) {
      margin: 2rem 5rem 1rem;
    }

    &-item {
      display: flex;
      flex-direction: column;
      position: relative;
      margin: 0 1rem 0.5rem 1rem;
      width: calc(100% - 2rem);

      @include responsive(desktop) {
        flex-direction: row;
        margin: 0;
        width: 100%;
      }

      &-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        padding: 0.625rem 1rem;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) -1.87%, rgba(0, 0, 0, 0.352) 35.8%, rgba(0, 0, 0, 0.64) 100%);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        @include responsive(desktop) {
          margin: 0 0 0 1.5rem;
          padding: 1.5rem 0 1.1875rem 0;
          position: static;
          border-bottom: 1px solid rgba(31, 79, 128, 0.3);
          background: transparent;
        }
      }

      &-top {
        display: flex;
        flex-direction: column;
      }

      &__img-shadow-wrapper {
        box-shadow: inset 10px 10px 5px #ccc;

        @include responsive(desktop) {
          box-shadow: none;
        }
      }

      &__img {
        display: block;
        width: 100%;
        height: 12.5rem;
        object-fit: cover;
        position: relative;
        z-index: -1;

        @include responsive(desktop) {
          width: 18.75rem;
        }

        &-placeholder {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          border: 1px solid rgba(31, 79, 128, 0.3);

          &__icon {
            margin-bottom: 1rem;
          }

          &__text {
            color: rgba(31, 79, 128, 0.3);
            font-weight: 600;
            font-size: 18px;
          }
        }
      }

      &__title {
        display: flex;
        width: 100%;
        margin-bottom: 0.25rem;
        font-size: 1.125rem;
        line-height: 140%;
        color: #ffffff;

        @include responsive(desktop) {
          color: #091630;
        }
      }

      &__statistics {
        font-size: 1rem;
        line-height: 140%;
        color: #ffffff;

        @include responsive(desktop) {
          color: $c-primary;
          opacity: 0.5;
        }
      }

      &-row {
        display: flex;
        align-items: center;

        > * + * {
          margin-left: 1rem;
        }

        &__separator {
          width: 1px;
          height: 1.875rem;
          background: $c-primary;
          opacity: 0.5;
        }
      }
    }
  }

  .links-table {
    @include responsive(desktop) {
      margin-top: 2.5rem;
    }

    .sessions-widget {
      width: 100%;
      padding: 1.125rem 1.5rem;
      position: relative;

      &:before {
        content: '';
        display: block;
        background: #1F4F80;
        box-shadow: inset 0px -4px 8px rgba(0, 0, 0, 0.16), inset 0px 4px 8px rgba(0, 0, 0, 0.16);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;

        @include responsive(desktop) {
          left: 0;
          right: 0;
        }
      }

      &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 0.75rem;
        font-size: 0.875rem;
        line-height: 140%;
        color: #ffffff;

        &__see-more {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-weight: 500;
          color: #ffffff;
          text-decoration: none;
        }

        &__close {
          cursor: pointer;
        }
      }

      &-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-weight: 500;
        font-size: 1rem;
        line-height: 140%;
        color: #ffffff;

        &__see-more {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #ffffff;
          text-decoration: none;
        }
      }

      &-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-bottom: 1rem;

        @include responsive(desktop) {
          flex-direction: row;
        }

        > * + * {
          margin: 0.5rem 0 0 0;

          @include responsive(desktop) {
            margin: 0 0 0 0.5rem;
          }
        }

        &-item {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          align-items: center;
          width: 100%;
          padding: 0.375rem 1rem;
          background: #ffffff;
          border-radius: 0.25rem;
          font-size: 0.75rem;
          line-height: 140%;
          text-align: center;

          @include responsive(desktop) {
            font-size: 1rem;
          }

          &-identity {
            display: flex;
            flex-direction: column;
            text-align: left;

            &__time {
              @include responsive(desktop) {
                font-size: 0.875rem;
              }
            }
          }
        }
      }
    }
  }
  .sessions-table {
    .calendar-opener {
      opacity: 0.5;
      cursor: pointer;
    }

    .feedback-dock {
      display: flex;
      align-items: center;
      cursor: pointer;
      opacity: 0.5;
      padding: 0.5rem;
      transition: all 0.3s;

      > * + * {
        margin-left: 0.5rem;
      }

      &:hover {
        opacity: 1;
      }

      &.decorations {
        padding: 0.25rem 0.5rem;
        border: 1px solid rgba(31, 79, 128, 0.3);
        border-radius: 0.25rem;
      }

      &__btn {
        width: 1rem;
        height: 1rem;

        @include responsive(desktop) {
          width: 1.25rem;
          height: 1.25rem;
        }
      }

      &__separator {
        width: 1px;
        height: 1.25rem;
        background: $c-primary;
        opacity: 0.15;
      }
    }

    .feedback-widget {
      background: #f2f5f8;
      width: 100%;

      > * + * {
        border-top: 1px solid rgba(31, 79, 128, 0.5);
      }

      svg path {
        opacity: 0.5;
      }

      &-location {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 0.5rem;
        padding: 1rem;

        > :nth-child(odd) {
          font-size: 0.75rem;
          line-height: 140%;
          color: $c-primary;
          opacity: 0.5;
          text-align: left;
        }

        > :nth-child(even) {
          font-size: 0.75rem;
          line-height: 140%;
          color: #091630;
          text-align: right;
        }
      }

      &-contacts,
      &-feedback {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 0.5rem;
        padding: 1rem;

        > :nth-child(even) {
          font-size: 0.75rem;
          line-height: 140%;
          color: #000000;
        }
      }
    }

    .feedback-card {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      width: min(31.25rem, 90vw);
      font-weight: 400;
      font-size: 1rem;
      line-height: 140%;
      color: #091630;
      border: 1px solid #E9EDF2;
      box-shadow: 0 2px 12px rgba(31, 79, 128, 0.24);
      border-radius: 8px;
      text-align: left;
      padding: 1rem;
      position: relative;

      > *:not(.feedback-card__navigation) + * {
        border-top: 1px solid rgba(31, 79, 128, 0.3);
      }

      svg path {
        opacity: 0.5;
      }

      &__navigation {
        position: absolute;
        cursor: pointer;

        &.left {
          top: 2rem;
          left: -2.25rem;
        }

        &.right {
          top: 2rem;
          right: -2.25rem;
        }

        &.disabled {
          opacity: 0.5;
          cursor: default;
        }

        svg path {
          opacity: 1;
          fill: #ffffff;
          filter: drop-shadow(0 2px 8px rgba(31, 79, 128, 0.24));
        }
      }

      &-summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0 1rem 0;

        &-rate {
          display: flex;
          align-items: center;
        }
      }

      &-contacts,
      &-feedback {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 0.5rem;
        padding: 1rem 0;
      }

      &-feedback {
        padding: 1rem 0 0 0;
      }
    }
  }
}

.lightbox {
  //display: contents;
  box-shadow: 0 0 0 6000rem rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

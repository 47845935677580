.breadcrumbs {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0;
  height: 2.75rem;
  max-width: 100%;

  @include responsive(desktop) {
    padding: 1.5rem 2.5rem 0;
  }

  &__back {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    color: $c-primary;
    text-decoration: none;
    @extend .t-lg;

    @include responsive(desktop) {
      margin-right: 2.25rem;
    }
  }

  &__element {
    display: flex;
    align-items: center;
    color: $c-primary;
    @extend .t-lg;
    a {
      color: $c-primary;
      text-decoration: none;
    }

    .clickable {
      cursor: pointer;
    }
  }

  &-inner {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }

  .delimiter {
    margin: 0 1rem;
  }
}

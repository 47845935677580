.toolbar-hider {
  position: absolute;
  top: 1.5rem;
  right: 0;
  z-index: 100;

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0 2px 12px rgba(31, 79, 128, 0.3);
    border-radius: 0.5rem 0 0 0.5rem;
    padding: 0.375rem 0;
    cursor: pointer;
  }

  &-content {
    position: absolute;
    top: 50%;
    left: -0.5rem;

    .toolbar {
      position: static;
    }
  }
}

.toolbar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;

  &-btns {
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0 2px 20px rgba(31, 79, 128, 0.3);
    border-radius: 0.5rem;
    padding: 0.25rem;
    margin: 0 -0.25rem;
  }

  &__btn {
    filter: drop-shadow(0px 0px 5px rgba(0, 46, 94, 0.4));
    background: #ffffff;
    border-radius: 0.5rem;
    margin-right: 0.25rem;
    border: none;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    cursor: pointer;

    svg path {
      transition: all 0.3s;
      fill: rgba(31, 79, 128, 0.3);
    }

    &:hover {
      filter: drop-shadow(0px 0px 5px rgba(0, 46, 94, 0.4));

      svg path {
        fill: $c-primary;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &__label {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.25rem;
    margin-top: 0.25rem;
    background: #e9edf2;
    box-shadow: 0 0 10px rgba(31, 79, 128, 0.16);
    color: #091630;
    text-align: center;
    @extend .t-md;
  }
}

.home-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.home-main {
  // min-height: 49.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.875rem 1rem 7.5rem 1rem;
  min-height: calc(100vh - 3.5rem);

  @include responsive(desktop) {
    padding: 4.25rem 6.875rem 7.5rem 6.875rem;
  }

  .page-back-image {
    width: auto !important;
  }

  &-preloader-background {
    position: fixed;
    top: 2.5rem;
    right: 0;
    bottom: 0;
    left: 20%;
    background: linear-gradient(
      269.67deg,
      rgba(143, 167, 192, 0.3) 36.56%,
      rgba(207, 217, 228, 0.19) 75.09%,
      rgba(255, 255, 255, 0.24) 99.66%
    );
  }

  &__subtitle {
    @extend .t-title;
    color: $c-primary;

    @include responsive(desktop) {
      margin-top: 2.375rem;
    }
  }

  &__title {
    font-size: 2.75rem;
    line-height: 140%;
    color: $c-primary;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 3.75rem;
    text-align: center;

    @include responsive(desktop) {
      font-size: 4.5rem;
      text-align: left;
    }
  }

  &-dashboard {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 7.5rem;

    @include responsive(desktop) {
      justify-content: flex-start;
    }

    &__item {
      position: relative;
      border-radius: 50%;
      padding: 0.25rem;
      width: 11.25rem;
      height: 11.25rem;
      background: conic-gradient(
        from -0.28deg at 50% 50%,
        #78efff 0deg,
        #1f4f80 50.14deg,
        #72b8ff 181.4deg,
        #78efff 360deg
      );
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      margin-right: 0.625rem;

      @include responsive(desktop) {
        margin-right: 3rem;
      }

      &-content {
        background: white;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      b {
        font-size: 3rem;
        color: $c-primary;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        line-height: 100%;
      }

      span {
        @extend .t-md;
        color: $c-primary;
      }
    }
  }

  &__start {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 140%;
    color: $c-primary;
    margin: auto 0 2.5rem;
    cursor: pointer;

    svg {
      animation-name: arrow_animation;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      margin-left: 1rem;
      transform: translateY(-0.5rem);
    }

    @keyframes arrow_animation {
      0% {
        transform: translateY(-0.25rem);
      }
      50% {
        transform: translateY(0.25rem);
      }
      100% {
        transform: translateY(-0.25rem);
      }
    }
  }
}
.home-cases {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 3.75rem 0 2rem 0;

  @include responsive(desktop) {
    align-self: center;
    padding: 3.75rem 5rem 2rem 5rem;
    align-items: center;
    width: 100%;
  }

  &.cases-page {
    padding-top: 1.375rem;
  }

  .t-title {
    font-weight: 600;
    font-size: 3rem;
    line-height: 140%;
    color: $c-primary;
    margin-bottom: 2rem;

    @include responsive(desktop) {
      align-self: flex-start;
    }
  }

  .home-cases-tabs-ctrl {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    @include responsive(desktop) {
      width: calc(25rem * 3 + 2rem * 2);
    }

    &-section {
      display: flex;
      width: 100%;

      @include responsive(mobile, tablet) {
        margin: 0 1rem;
      }

      @include responsive(desktop) {
        width: 25rem;
      }
    }

    &__item {
      @extend .btn-white, .t-md;
      flex: 1;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      &.active {
        @extend .btn-secondary;
      }
    }
  }

  .list-wrapper {
    @include responsive(desktop) {
      width: calc(25rem * 3 + 2rem * 2);
    }
  }

  .share-form {
    &-label {
      > * + * {
        margin-top: 1rem;
      }

      &__description {
        font-weight: normal;
        color: #091630;
      }

      &__name {
        color: #1F4F80;
      }
    }

    &-copy {
      display: flex;
      width: 100%;
      position: relative;

      &__notification {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: calc(100% + 4px);
        right: 0;
        padding: 6px 30px;
        background: rgba(0, 0, 0, 0.5);
        background: #E9EDF2;
        box-shadow: 0 0 10px rgba(31, 79, 128, 0.16);
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        text-align: center;
        color: #091630;
      }
    }
  }
}

.case-card {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(31, 79, 128, 0.3);
  padding: 1rem 1.5rem 1rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  min-height: 16.75rem;

  @include responsive(desktop) {
    width: 25rem;
    height: auto;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }

  &__type {
    padding: 0 0.5rem;
    margin-bottom: 0.75rem;
    border: 1px solid $c-primary;
    border-radius: 0.25rem;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 140%;
    color: $c-primary;
    opacity: 0.5;
  }

  &-top {
    display: flex;
    align-items: center;
    height: 2.75rem;

    &-slot {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      margin-left: auto;
      color: $c-primary;
      font-size: 0.75rem;

      > :not(:last-child) {
        margin-right: 0.5rem;
      }
    }

    & > img {
      max-width: 2.75rem;
      max-height: 2.75rem;
    }
  }
  .rented-label {
    display: block;
    text-align: right;
  }
  &-rate {
    align-items: center;
    display: flex;
    margin-left: auto;
    color: $c-primary;
  }

  &__name {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 140%;
    color: #373737;
    text-transform: uppercase;

    &-slot {
      display: flex;
      align-items: center;
      margin-left: auto;
      color: $c-primary;

      > :not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }

  &__text {
    color: #373737;
    @extend .t-md;
    margin-bottom: 3rem;
    overflow-wrap: break-word
  }

  .btn-primary {
    margin: 3rem 0 0 auto;
  }

  &-bottom {
    display: flex;
    align-items: center;
    margin-top: auto;

    .case-status {
      display: flex;
      align-items: center;
      color: $c-primary;
    }

    .btn-primary {
      margin: 0 0 0 auto;

      &.disabled {
        background: $c-grey;
      }
    }
  }

  &__btn {
    @extend .btn-white-icon;
    margin-right: 1rem;
    color: $c-primary;
    text-decoration: none;
  }

  .btn-icon {
    opacity: 0.5;
    transition: all 0.3s;

    &.disabled {
      filter: grayscale(100%);
      opacity: 0.15;
      cursor: default;
    }

    &:not(.disabled):hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}

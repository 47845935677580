.overlay-link {
  display: block;
  cursor: pointer;
  position: relative;
  text-decoration: none;

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 0;
    left: 0;
    bottom: 0;
    background: rgba(55, 55, 55, 0.5);
    overflow: hidden;
    z-index: 15;
    transform-origin: bottom center;
    transition: all 0.3s ease-out;

    &__text {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 140%;
      color: #FFF;
      text-align: center;
    }

    > * + * {
      margin-left: 0.5rem;
    }
  }

  &:hover &-content {
    max-height: 100%;
  }
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(9, 22, 48, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal {
    position: relative;
    width: min(31.25rem, 90%);
    padding: 1.5rem 2.25rem;
    background: #ffffff;

    &__close {
      position: absolute;
      top: 1.5rem;
      right: 2.25rem;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        transform: scale(1.05);
      }
    }

    &__title {
      font-weight: 500;
      font-size: 1.25rem;
      color: $c-primary;
      margin-bottom: 1.5rem;
    }

    &__text {
      color: #091630;
      @extend .t-lg;
      margin-bottom: 1.5rem;
      .t-lg {
        margin-bottom: 0.5rem;
      }
    }

    &-bottom {
      display: flex;
      align-items: center;
    }

    .btn-primary {
      margin-left: auto;
      font-size: 1.125rem;
    }
  }
}

.editable-field-wrapper {
  display: flex;
  align-items: center;
  button.editable-field__btn {
    border: none;
    background: none;
    padding-right: 0 !important;
    cursor: pointer;
  }
}
.editable-field {
  position: relative;
  input {
    @extend .form__input;
    font-weight: 500;
    padding-left: 0.5rem;
    color: #1f4f80;

    &:read-only {
      color: $c-primary;
      border-bottom: 1px solid rgba(31, 79, 128, 0.3);
    }
  }
  .form__maxlength {
    position: absolute;
    top: 100%;
    right: 0;
  }
  &.error {
    input {
      border-color: $c-red;
    }
  }
}

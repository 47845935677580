.error-code-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin: 2rem 1rem;
  position: relative;

  @include responsive(desktop) {
    margin: 2rem 6.875rem 7.875rem 6.875rem;
  }

  &__art {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;

    @include responsive(desktop) {
      width: auto;
      height: 100%;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    bottom: 0;

    &__title {
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 140%;
      color: $c-primary;
      margin-bottom: 0.5rem;

      @include responsive(desktop) {
        font-size: 2.5rem;
        margin-bottom: 1rem;
      }
    }

    &__message {
      max-width: 32.25rem;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 140%;
      color: #000000;
      margin-bottom: 1rem;

      @include responsive(desktop) {
        font-size: 1.3rem;
        margin-bottom: 1.5rem;
      }
    }

    &__link {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 140%;
      color: $c-primary;
      text-decoration: none;
    }
  }
}

.skeleton-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background: #fff;
}
.skeleton {
  background: rgba(31, 79, 128, 0.5);
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    transform: translateX(-100%);
    animation: skeleton-animation 2s linear infinite;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
  }
}

@keyframes skeleton-animation {
  100% {
    transform: translateX(100%);
  }
}

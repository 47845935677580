html {
  font-family: $font-primary;
  // font-size: 1.25vw;
  scroll-behavior: smooth;
}

button,
input,
textarea,
select {
  font-family: $font-primary;
}

body {
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
}
p {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .page-back-image {
      z-index: -1;
      position: absolute;
      bottom: 0;
      right: 0;
      height: 60%;
      object-fit: contain;
      mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1.0) 100%);

      @include responsive(desktop) {
        top: 0;
        bottom: unset;
        height: 100%;
        mask-image: none;
      }
    }
  }
}

.t-sm {
  font-size: 0.75rem;
  line-height: 140%;
}
.t-md {
  font-size: 0.875rem;
  line-height: 140%;
}
.t-lg {
  font-size: 1rem;
  line-height: 140%;
}
.t-xl {
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 400;
}
.t-title {
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 140%;
  text-align: center;

  @include responsive(desktop) {
    font-size: 2.25rem;
    text-align: left;
  }
}
.t-center {
  text-align: center;
}
.fw-500 {
  font-weight: 500;
}
.op-50 {
  opacity: 50%;
}
.t-bold {
  font-weight: bold;
}

.ml-auto {
  margin-left: auto;
}

.down-line-hover-effect {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 0;
    background: $c-primary;
    opacity: 0.5;
    transition: all 0.3s;
  }

  &:hover:not(:disabled) {
    &::after {
      width: 100%;
    }
  }
}

.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}

.spinner-wrapper {
  position: fixed;
  top: 3.5rem;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .spinner {
    position: relative;
    animation: spinner 1s infinite;
    width: 140px;
    height: 138px;
    transform: matrix(1, 0, 0, -1, 0, 0);

    div:nth-child(2) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 95px;
      height: 95px;
      background: #ffffff;
      border-radius: 50px;
    }
    div:nth-child(1) {
      position: absolute;
      width: 100px;
      height: 100px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background: conic-gradient(
        from 90.19deg at 50% 50%,
        #1f4f80 0deg,
        rgba(182, 198, 214, 0.63) 157.5deg,
        #ffffff 358.13deg,
        #1f4f80 360deg
      );
      border-radius: 50px;
      // transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

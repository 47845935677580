.list-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  .list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1rem;
  }

  .list__no-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    color: rgba(31, 79, 128, 0.3);
    padding: 5rem;
  }

  .swiper {
    width: 100%;
    margin-bottom: 1rem;
  }

  .swiper-slide {
    width: calc(100% - 2rem);
    height: auto;
  }
}

.video-recorder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  &__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(88, 88, 88, 0.8);
    filter: blur(0.25rem);
    backdrop-filter: blur(0.25rem);
    z-index: 999;
  }

  &-cancel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 18.75rem;
    height: 18.75rem;
    background: rgba(31, 79, 128, 0.3);
    backdrop-filter: blur(30px);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1002;
    transform: translate(-50%, -50%);

    &__timer {
      font-weight: 500;
      font-size: 6rem;
      line-height: 140%;
      text-align: center;
      color: #FFFFFF;
    }

    &__text {
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 140%;
      text-align: center;
      color: #FFFFFF;
      opacity: 0.5;
      position: absolute;
      bottom: 1.5rem;
    }
  }

  &-controls {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    background: rgba(31, 79, 128, 0.3);
    backdrop-filter: blur(1.875 rem);
    border-radius: 0.75rem;
    position: absolute;
    bottom: 2.5rem;
    z-index: 1002;

    &-play-pause {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.125rem;
      border-radius: 50%;
      background: #FFFFFF;
      border: 1px solid #EA4C4C;
      cursor: pointer;

      &__icon {
        width: 100%;
        height: 100%;
        border-radius: 1.25rem;
        background: #EA4C4C;
      }
    }

    &-time {
      display: flex;
      align-items: center;

      &__timer {
        margin: 0 2.375rem 0 0.5rem;
        font-size: 1rem;
        line-height: 140%;
        color: #ffffff;
      }
    }

    &__finish {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
    }
  }

  video {
    width: 100%;
    height: 100%;
    z-index: 1001;
  }
}

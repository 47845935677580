// FONTS
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

$font-primary: "Montserrat", sans-serif;

// VARIABLES
$c-primary: #1F4F80;
$c-red: #af0404;
$c-brown: #ac8d6d;
$c-grey: rgba(9, 22, 48, 0.3);
$c-l-grey: #f4f4f4;

.c-primary {
  color: $c-primary;
}

.c-grey {
  color: rgba(9, 22, 48, 0.3);
}

.c-blue {
  color: rgba(31, 79, 128, 0.3);
}

.c-white {
  color: white;
}

// MIXINS
@import "./mixins";

// GLOBAL STYLES
@import "./global";

// PAGES
@import "./pages/auth";
@import "./pages/home";
@import "./pages/analytics";
@import "./pages/users";
@import "./pages/case-preview";
@import "./pages/mobile-oh-no";
@import "./pages/error-code";

// BLOCKS
@import "./blocks/header";
@import "./blocks/footer";
@import "./blocks/form";
@import "./blocks/button";
@import "./blocks/list";
@import "./blocks/pagination";
@import "./blocks/breadcrumbs";
@import "./blocks/modal";
@import "./blocks/notifications";
@import "./blocks/file-loader";
@import "./blocks/toolbar";
@import "./blocks/video-recorder";
@import "./blocks/editable-field";
@import "./blocks/table";
@import "./blocks/tool-positioner";
@import "./blocks/skeleton";
@import "./blocks/overlay-link";
@import "./blocks/lightbox";
@import "./blocks/uploading-indicator";

.icon-wrapper {
  svg {
    width: 100%;
    height: 100%;
  }
}

@import "../../node_modules/react-phone-input-2/lib/style.css";
@import "../../node_modules/react-datepicker/dist/react-datepicker.css";

.custom-phone {
  .form-control {
    width: 100%;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1rem;
    height: auto;
    border-radius: 0;
    border: 1px solid rgba(31, 79, 128, 0.3);
    transition: all 0.3s;
  }
  .flag-dropdown {
    border: 1px solid rgba(31, 79, 128, 0.3);
    transition: all 0.3s;
  }

  &.error {
    .form-control,
    .flag-dropdown {
      border-color: $c-red;
    }
  }
}

.react-datepicker {
  background: #ffffff;
  border: 1px solid #E9EDF2;
  box-shadow: 0 2px 12px rgba(31, 79, 128, 0.24);
  border-radius: 8px;
  padding: 1.5rem;
  font-family: $font-primary;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 140%;
  color: #333333;

  &__triangle {
    display: none;
  }

  &__navigation {
    transform: scale(0.8);

    &-icon{
      &:before {
        border-color: $c-primary !important;
        opacity: 0.5;
      }
    }

    &:hover &-icon:before {
      opacity: 1;
    }

    &--previous {
      top: 1.1rem;
      left: 1.6rem;
    }

    &--next {
      top: 1.1rem;
      right: 1.6rem;
    }
  }

  &__header {
    background: #ffffff;
    border: none;
    border-radius: 8px 8px 0 0;
    padding: 0;
  }

  &__current-month {
    font-weight: 700;
    font-size: 1.25rem;
  }

  &__month {
    margin: 1rem 0 0 0;
  }

  &__day {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    margin: 0.3125rem;
    border: 1px solid transparent;
    outline: none;

    &-names {
      margin: 1.5rem 0 0 0;
    }

    &-name {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      color: #9c9d9f;
      margin: 0 0.3125rem;
    }

    &--outside-month {
      color: #9c9d9f;
    }

    &--keyboard-selected {
      background: $c-primary;
      border-color: $c-primary;
      color: #ffffff;

      &:hover {
        color: #ffffff;
      }
    }

    &--in-range {
      background: rgba(31, 79, 128, 0.05);
      border-color: transparent;
      color: #000000;

      &:hover {
        background: #f0f0f0;
      }

      &.react-datepicker__day--outside-month {
        opacity: 0.5;
      }
    }

    &--range-start {
      background: #ffffff;
      color: $c-primary;
      border-color: $c-primary;

      &:hover {
        background: rgba(31, 79, 128, 0.2);
        color: $c-primary;
      }
    }

    &--range-end {
      background: $c-primary;
      border-color: $c-primary;
      color: #ffffff;

      &:hover {
        background: rgba(31, 79, 128, 0.8);
        color: #ffffff;
      }
    }
  }
}

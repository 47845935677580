.notifications-wrapper {
  position: fixed;
  top: 6.5rem;
  right: 1.5rem;
  z-index: 9999;

  .notification {
    display: flex;
    align-items: center;
    width: 25rem;
    max-width: 25rem;
    padding: 1rem 1.5rem;
    margin-bottom: 0.25rem;
    border-radius: 0.75rem;
    border: 1px solid rgba(31, 79, 128, 0.3);
    backdrop-filter: blur(0.25rem);

    &-content {
      margin: 0 1rem;
      flex-grow: 1;
    }

    &__icon {
      width: 2rem;
      height: 2rem;
    }

    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #091630;
      margin-bottom: 0.5rem;
    }

    &__close {
      @extend .btn, .btn-icon;
    }

    &__message {
      font-size: 16px;
      line-height: 140%;
      color: #091630;
    }

    &.warning {
      background: linear-gradient(0deg, #fff5eb, #fff5eb), #ffffff;
      border: 1px solid rgba(255, 120, 68, 0.3);
      box-shadow: 0px 0px 10px rgba(255, 245, 235, 0.16);
    }
    &.info {
      background: linear-gradient(
          0deg,
          rgba(200, 227, 255, 0.4),
          rgba(200, 227, 255, 0.4)
        ),
        #ffffff;
      border: 1px solid rgba(31, 79, 128, 0.3);
      box-shadow: 0px 0px 10px rgba(31, 79, 128, 0.16);
    }
    &.error {
      background: linear-gradient(
          0deg,
          rgba(255, 200, 200, 0.3),
          rgba(255, 200, 200, 0.3)
        ),
        #ffffff;
      border: 1px solid rgba(234, 76, 76, 0.3);
      box-shadow: 0px 0px 10px rgba(217, 73, 73, 0.16);
    }
    &.success {
      background: linear-gradient(
          0deg,
          rgba(207, 255, 200, 0.4),
          rgba(207, 255, 200, 0.4)
        ),
        #ffffff;
      border: 1px solid rgba(31, 128, 52, 0.3);
      box-shadow: 0px 0px 10px rgba(31, 128, 52, 0.16);
    }
  }
}

.file-uploader {
  &.preview {
    position: relative;
    margin: 0.5rem;

    .remove-btn {
      position: absolute;
      top: 0;
      left: 100%;
    }
  }

  &.record-starter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 7rem 2rem 3.125rem 2rem;

    &__btn {
      margin-top: 0.25rem;
      padding: 0.625rem 2.25rem;
      border-radius: 0.25rem;
    }

    &__or {
      font-size: 1rem;
      line-height: 140%;
      text-align: center;
      color: $c-primary;
      margin-top: 3.125rem;
    }
  }

  &.form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;

    border: 1px dashed #1f4f80;
    // flex-grow: 1;
    max-height: 30rem;

    input[type='file'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;

      &.drag-only {
        cursor: default;
      }
    }

    &.status {
      height: 30rem;
      text-align: center;
      color: $c-primary;
    }

    &__browse-prologue {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
      font-size: 1rem;
      line-height: 140%;
      text-align: center;
      color: rgba(31, 79, 128, 0.5);
    }

    &__browse-btn {
      margin-top: 0.25rem;
      padding: 0.625rem 1rem;
      border-radius: 0.25rem;
      position: relative;
    }

    &__title {
      font-size: 1rem;
      line-height: 140%;
      color: $c-primary;
      text-align: center;
    }
    &__subtitle {
      font-size: 0.875rem;
      line-height: 140%;
      color: #091630;
      opacity: 0.3;
    }
  }
}

.uploading-indicator {
  margin-top: 0.7rem;
  width: 20rem;
}

.crop {
  display: contents;
}

.crop-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #09163080;

  .ReactCrop {
    max-height: 70vh;
    width: auto !important;
  }
}

.ReactCrop__crop-selection {
  border-radius: 0.5rem;
}

.video-preview > .modal-wrapper > .modal {
  width: 50%;

  .submit-row {
    display: flex;
    align-items: center;
    width: 100%;
  }

  video {
    width: 100%;
    max-height: 60vh;
  }
}

.image-form-preview {
  display: flex;
  align-items: flex-start;
}

.image-form-wrapper {
  display: flex;
  flex-grow: 1;
  // align-items: flex-end;

  &.small .image-form {
    width: 7.25rem;
    height: 3.75rem;
    flex-grow: 0;
    margin: 0;
    margin-right: 0.5rem;
    svg {
      margin-bottom: 0;
    }
  }

  &.medium .image-form {
    width: 15rem;
    height: 27rem;
    flex-grow: 0;
    text-align: center;
    margin: 0;
    margin-right: 0.5rem;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 3.5rem;
  background: $c-primary;
  position: relative;
  z-index: 100;
  overflow: hidden;

  @include responsive(desktop) {
    flex-direction: row;
    overflow: unset;
  }

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 3.5rem;
    position: relative;
    margin-right: 1.875rem;
  }

  &__logo {
    width: 7.5rem;
    // height: 3.5rem;
    margin-left: 1.875rem;
    img {
      height: 100%;
      max-width: 100%;
    }
  }

  &-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 3.5rem);
    padding: 0.5rem 0;

    @include responsive(desktop) {
      flex-direction: row;
      margin-left: auto;
      min-height: auto;
      height: 100%;
    }

    &__toggle {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__link {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      margin: 1rem 0;
      color: #fff;
      text-decoration: none;
      font-size: 1.05rem;
      position: relative;
      cursor: pointer;

      @include responsive(desktop) {
        padding: 0;
        margin: 0 1.5rem;
        height: 100%;
      }

      &:last-child {
        margin-top: auto;

        @include responsive(desktop) {
          margin-top: 0;
        }
      }

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 1px;
        bottom: 0;
        left: 0;
        background: #ffffff;
        transform-origin: bottom left;
        transition: transform 0.5s ease-out;
      }

      &:hover:after {
        transform: scaleX(1);
      }

      // .icon-wrapper {
      //   margin-right: 0.75rem;
      // }

      // &.right {
      //   .icon-wrapper {
      //     margin-right: 0;
      //     margin-left: auto;
      //   }
      // }
    }
    &-dropdown {
      position: relative;
      height: 100%;
      cursor: pointer;

      .header-nav__link svg path {
        fill: white;
      }

      &-content {
        background: $c-primary;
        position: absolute;
        top: calc(100% + 0.5rem + 1px);
        right: 0;
        width: 12rem;

        .header-nav__link {
          height: 2.5rem;
          border-left: none;
          border-bottom: 1px solid #ffffff;
          padding: 0.5rem 1.5rem;
          margin: 0;
          transition: all 0.3s;

          &:after {
            display: none;
          }

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            background: #4C7399;
          }
        }
      }
    }
  }
}

.header.case-header {
  background: #ffffff;
  box-shadow: 0 0 6px rgba(31, 79, 128, 0.2);

  .header__logo {
    width: auto;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #1F4F80;
  }

  .header-nav__link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.875rem;
    margin-left: 0;
    margin-right: 1.5rem;
    background: #FFFFFF;
    color: $c-primary;
    font-size: 1rem;
    cursor: pointer;
    position: relative;

    &.icon {
      width: 1.875rem;
      border-radius: 7px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
      transition: all 0.3s;

      &:hover {
        box-shadow: 0 0 5px rgba(0, 46, 94, 0.4);
      }

      &:active {
        box-shadow: 0 0 5px rgba(0, 46, 94, 0.7);
      }
    }

    &:after {
      display: none;
    }
  }

  .header-nav-dropdown {
    &-content {
      display: flex;
      flex-direction: column;
      left: unset;
      right: 1.5rem;
      top: 4rem;
      width: 30rem;
      padding: 1.5rem 1rem;
      color: #FFF;
    }

    &__title {
      font-weight: 500;
      font-size: 1.25rem;
      padding-left: 1rem;
      margin-bottom: 1.5rem;
    }
  }

  .header-nav-tip {
    width: max-content;
    max-width: 18rem;
    background: #E9EDF2;
    box-shadow: 0 0 10px rgba(31, 79, 128, 0.16);
    padding: 0.25rem 1rem;
    position: absolute;
    top: calc(100% + 4px);
    font-weight: 500;
    font-size: 0.75rem;
    text-align: center;
    color: #091630;
  }

  .header-nav__finish-btn {
    padding: 0.3125rem 1.25rem;
    margin: 0 3.75rem 0 2.25rem;
    background: $c-primary;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 140%;
    color: #ffffff;
    transition: all 0.3s;

    &:hover {
      background: #4c7399;
    }
    &:active {
      box-shadow: inset 0 0 5px rgba(31, 79, 128, 0.73);
    }
  }

  .info-link {
    display: flex;
    border: 1px solid #ffffff;
    margin-bottom: 1rem;

    &__content {
      padding: 1rem;
      border-right: 1px solid #ffffff;
      height: 100%;
      flex-grow: 1;
      max-width: calc(100% - 10rem);
    }
    &__step {
      padding: 1rem;
      flex-grow: 1;
      border-right: 1px solid #ffffff;
      max-width: 5rem;
      min-width: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
    &__icon {
      max-width: 5rem;
      min-width: 5rem;
      padding: 1rem;
      flex-grow: 1;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      transition: all 0.3s;
      svg path {
        transition: all 0.3s;
      }
      &:hover {
        background: white;
        svg path {
          fill: $c-primary;
        }
      }
    }

    &-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.auth-page {
  flex-grow: 1;
  padding: 1rem;
  position: relative;

  @include responsive(desktop) {
    padding: 4rem 9.375rem;
  }

  .t-title {
    text-align: center;
    font-size: 1.5rem;

    @include responsive(desktop) {
      text-align: left;
    }
  }

  .form {
    margin-top: 1.5rem;
    width: 100%;

    @include responsive(desktop) {
      width: 31.25rem;
    }

    &__field > input {
      background: #ffffff;
    }

    &__field:last-child {
      margin-bottom: 0;
    }

    &__label {
      font-weight: 400;
      font-size: 1rem;
      line-height: 140%;
      color: #1F4F80;

      &:after {
        content: '*';
        color: #AF0404;
        opacity: 0.75;
        margin-left: 0.25rem;
      }
    }

    &__error{
      top: 0;
      left: calc(100% + 1rem);
    }

    .submit-row {
      display: flex;
      align-items: center;
      width: 100%
    }

    .btn-primary {
      margin-left: auto;
    }
  }

  a {
    color: $c-primary;
    text-decoration: none;
  }

  .password-message {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .forget-link {
      @extend .t-md;
    }
  }

  .required-message {
    opacity: 0.5;
    color: $c-primary;

    &:before {
      content: '*';
      color: $c-red;
      margin-right: 0.25rem;
    }
  }
}

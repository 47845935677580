.footer {
  background: $c-primary;
  height: 1.4rem;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $c-l-grey;
  @extend .t-sm;

  @include responsive(desktop) {
    height: 2.25rem;
    font-size: 0.8rem;
  }
}

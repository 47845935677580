.mobile-oh-no {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 1rem;
  background: url("../../../public/assets/images/errors/mobile-oh-no.svg") no-repeat center;
  text-align: center;

  @include responsive(desktop) {
    padding: 6.875rem;
  }

  &__title {
    font-weight: 500;
    font-size: 4.5rem;
    line-height: 140%;
    color: $c-primary;
    margin-bottom: 1rem;
  }

  &__message {
    max-width: 32.25rem;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 140%;
    color: #000000;
    margin-bottom: 2.5rem;
  }

  &__link {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 140%;
    color: $c-primary;
    text-decoration: none;
  }
}

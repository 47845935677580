.uploading-indicator {
  display: grid;
  gap: 0.25rem;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;

  &__filename {
    font-size: 1rem;
    line-height: 140%;
    color: $c-primary;
  }

  &__progress {
    font-size: 1rem;
    line-height: 140%;
    color: $c-primary;
    text-align: right;
  }

  &__progress-bar {
    grid-column: 1 / 3;
    width: 100%;
    height: 0.4rem;
    background: rgba(31, 79, 128, 0.1);
    border-radius: 1rem;
    position: relative;

    &-inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background: $c-primary;
      border-radius: 0.625rem;
      transition: all 0.3s;
    }
  }
}

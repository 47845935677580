@use 'sass:list';

$mobile-breakpoint: 576px;
$tablet-breakpoint: 768px;

$mobile-condition: "only screen and (max-width: #{$mobile-breakpoint})";
$tablet-condition: "only screen and (min-width: #{$mobile-breakpoint + 1px}) and (max-width: #{$tablet-breakpoint})";
$desktop-condition: "only screen and (min-width: #{$tablet-breakpoint + 1px})";

@mixin responsive($breakpoints...) {
  @if (list.index($breakpoints, mobile)) {
    @media #{$mobile-condition} {
      @content;
    }
  }

  @if (list.index($breakpoints, tablet)) {
    @media #{$tablet-condition} {
      @content;
    }
  }

  @if (list.index($breakpoints, desktop)) {
    @media #{$desktop-condition} {
      @content;
    }
  }
}

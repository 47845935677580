.dfh {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.users-page {
  @extend .dfh;
  margin: 0 0 1rem;

  .users-list {
    @extend .dfh;
    margin: 1rem 0;

    @include responsive(desktop) {
      margin: 2rem 5rem 0;
    }
  }

  .users-form {
    margin: 1.5rem 1rem;
    color: #000000;

    @include responsive(desktop) {
      margin: 2rem 5rem 1rem;
    }

    &-top {
      display: flex;
      align-items: center;
      border-bottom: 2px solid rgba(31, 79, 128, 0.15);
      padding-bottom: 1rem;
      margin-bottom: 1rem;

      @include responsive(desktop) {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
    .t-title {
      font-weight: 500;
      font-size: 1rem;
      margin-right: auto;
    }
    &__btn {
      background: none;
      border: none;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      margin-left: 2rem;
      padding: 0;
    }

    .form__field {
      flex-direction: row;
      align-items: center;
      label {
        @extend .t-lg;
        font-weight: 500;
        min-width: 30vw;
        max-width: 30vw;
        margin-bottom: 0;
        text-transform: uppercase;

        @include responsive(desktop) {
          min-width: 15rem;
          max-width: 15rem;
        }
      }

      .form__input-wrapper {
        width: 100%;

        @include responsive(desktop) {
          width: 31.25rem;
        }
      }

      .form__message {
        left: 16rem;
        top: calc(100% - 1.4rem);
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        border-bottom: 2px solid rgba(31, 79, 128, 0.15);
        padding-bottom: 1rem;
        margin-bottom: 1rem;

        @include responsive(desktop) {
          padding-bottom: 1.5rem;
          margin-bottom: 1.5rem;
        }
      }

      .form__radio-wrapper {
        margin-right: 0.5rem;
      }
    }

    .btn-primary {
      margin: 0 0 0 auto;
    }
  }
}

.more-btn {
  flex-grow: 0;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  cursor: pointer;

  &-content {
    width: 14rem;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 100;
  }

  &__link {
    display: block;
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    font-size: 1.125rem;
    color: $c-primary;
    background: #fff;
    border: 1px solid rgba(31, 79, 128, 0.3);
    transition: all 0.3s;
    width: 100%;

    &:hover {
      background: #d2dce6;
    }
  }
}

.user-plan {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;
  &__value {
    width: 100%;
    span {
      margin-left: 1rem;
    }
  }
  &__date {
    color: $c-primary;
    margin-right: auto;
  }
}

// .settings {
//   .users-form .form__field {
//     &:nth-child(1) {
//       // border-bottom: none;
//       // padding-bottom: 0;
//       // margin-bottom: 1rem;
//     }
//   }
// }
